@tailwind base;
@tailwind components;
@tailwind utilities;

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

#root {
	width: 100vw;
	height: 100vh;
}

.leaflet-container {
	width: 500px;
	height: 500px;
}

@media screen and (max-width: 600px) {
	.leaflet-container {
		width: 280px;
	}
}

.product.selected {
	background-color: #f0511233;
}
